import React, { ReactNode, ReactNodeArray, useEffect } from 'react';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import bootstrapAppData from '../Data/Bootstrap';
import * as authClient from '../Data/AuthClient';
import FullPageSpinner from '../Components/Core/FullPageSpinner';
import { CodeRequestResponse, DriverLoginRequest } from '../Data/AuthClient';
import { User } from '../types/user';

interface AuthContextValues {
  user: User|null;
  redirectUserToAuth: () => void;
  acceptCallback: () => Promise<void>;
  signOut: () => Promise<void>;
  updateUser: (updates: Partial<User>) => Promise<User>;
  driverCodeRequest: (phoneNumber: string) => Promise<CodeRequestResponse>;
  driverLogin: (credentials: DriverLoginRequest) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextValues>({} as AuthContextValues);

interface IAuthProviderProps {
    children: ReactNode|ReactNodeArray
}

function AuthProvider(props: IAuthProviderProps) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  useEffect(() => {
    if (isSettled) {
      const params = new URLSearchParams(search);
      let redirect = params.get('redirect');
      if (redirect) {
        redirect = redirect.charAt(0) === '/' ? redirect : `/${redirect}`;
        history.push(redirect);
      }
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinner />;
    }
    if (isRejected) {
      return (
        <div>
          <p>{t('error.unspecific')}</p>
          {error && <pre>{error.message}</pre>}
        </div>
      );
    }
  }

  const acceptCallback = () => authClient.acceptCallback()
    .then(() => window.location.replace('/'))
    .then(reload);
  const signOut = () => authClient.signOut()
    .then(() => window.location.replace('/'))
    .then(reload);
  const updateUser = (updates: Partial<User>) => authClient.updateUser(updates);

  const driverLogin = (
    credentials: DriverLoginRequest,
  ) => authClient.driverLogin(credentials)
    .then(reload);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        redirectUserToAuth: authClient.redirectUser,
        acceptCallback,
        signOut,
        updateUser,
        driverCodeRequest: authClient.codeRequest,
        driverLogin,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
