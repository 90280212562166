import * as React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { useUser } from '../../Context/UserContext';
import { Notification } from '../../types/notifications';

interface MessageNotificationsListProps {
  notifications: Notification[];
}

const MessageNotificationsList = ({ notifications }: MessageNotificationsListProps) => {
  const { markRead } = useUser();
  const { i18n, t } = useTranslation('nav');
  const history = useHistory();

  return (
    <>
      <NavDropdown.Header>
        <strong>
          {t('links.message_notifications')}
        </strong>
      </NavDropdown.Header>
      {notifications.length > 0 && notifications.map((notification) => (
        <NavDropdown.Item
          key={notification.id}
          onClick={() => {
            markRead(notification.id);
            history.push(`/${i18n.language}/check-ins/${notification.data.check_in_id}`);
          }}
        >
          { notification.data.label ?? t('general:glossary.na') }
        </NavDropdown.Item>
      ))}
      {notifications.length === 0 && <Dropdown.Header>{t('links.notifications_empty')}</Dropdown.Header>}
    </>
  );
};

export default MessageNotificationsList;
