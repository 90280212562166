export default class Storage {
  static getAccessToken() {
    return localStorage.getItem('__access_token__');
  }

  static getRefreshToken() {
    return localStorage.getItem('__refresh_token__');
  }

  static setAccessToken(value: string) {
    localStorage.setItem('__access_token__', value);
  }

  static setRefreshToken(value: string) {
    localStorage.setItem('__refresh_token__', value);
  }

  static clear() {
    localStorage.removeItem('__access_token__');
    localStorage.removeItem('__refresh_token__');
  }
}
