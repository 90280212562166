import Axios, { AxiosRequestConfig } from 'axios';
import { PaginatedResultSet, ResultSet } from '../types/laravel';
import {
  CheckInRequestFilter,
  CheckIn,
  CheckInRequestIncludes,
  CheckInRequestSort,
  CheckInUpdateRequest,
  Message,
  MessageRequestIncludes,
  CheckInAssignRequest,
  CheckInReassignDestinationRequest,
  CheckInReassignWarehouseRequest,
  CheckInCompleteRequest,
  CheckInReopenRequest,
  CheckInMediaTypeEnum,
  Media,
} from '../types/check-in';

export default class AdminCheckIns {
  static async index(
    page: number = 1,
    per_page: number = 15,
    include: CheckInRequestIncludes[] = [],
    sort: CheckInRequestSort[] = [],
    filter?: CheckInRequestFilter,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<PaginatedResultSet<CheckIn[]>> {
    const request = await Axios({
      ...config,
      params: {
        page,
        per_page,
        include,
        sort,
        filter,
      },
      method: 'get',
      url: '/api/admin/check-ins',
    });
    return request.data;
  }

  static async show(
    check_in_id: string|number,
    include: CheckInRequestIncludes[] = [],
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<CheckIn>> {
    const request = await Axios({
      ...config,
      params: {
        include,
      },
      method: 'get',
      url: `/api/admin/check-ins/${check_in_id}`,
    });
    return request.data;
  }

  static async showMedia(
    check_in_id: string|number,
    media_type: CheckInMediaTypeEnum,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<Media[]>> {
    const request = await Axios({
      ...config,
      params: {
        media_type,
      },
      method: 'get',
      url: `/api/admin/check-ins/${check_in_id}/media`,
    });
    return request.data;
  }

  static async indexMessages(
    check_in_id: string|number,
    include: MessageRequestIncludes[] = [],
    sort: string[] = [],
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<Message[]>> {
    const request = await Axios({
      ...config,
      params: {
        include,
        sort,
      },
      method: 'get',
      url: `/api/admin/check-ins/${check_in_id}/messages`,
    });
    return request.data;
  }

  // Must be an admin to utilize this.
  static async checkIn(
    data: {},
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<CheckIn>> {
    const request = await Axios({
      ...config,
      data,
      method: 'post',
      url: '/api/admin/check-ins',
    });
    return request.data;
  }

  static async update(
    check_in_id: string|number,
    data: CheckInUpdateRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<CheckIn>> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}`,
    });
    return request.data;
  }

  static async message(
    check_in_id: string|number,
    message: string,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<Message>> {
    const request = await Axios({
      ...config,
      data: {
        message,
      },
      method: 'post',
      url: `/api/admin/check-ins/${check_in_id}/messages`,
    });
    return request.data;
  }

  static async assign(
    check_in_id: string|number,
    data: CheckInAssignRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}/assign`,
    });
    return request.data;
  }

  static async reassignDestination(
    check_in_id: string|number,
    data: CheckInReassignDestinationRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}/reassign-destination`,
    });
    return request.data;
  }

  static async reassignWarehouse(
    check_in_id: string|number,
    data: CheckInReassignWarehouseRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}/reassign-warehouse`,
    });
    return request.data;
  }

  static async complete(
    check_in_id: string|number,
    data: CheckInCompleteRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}/complete`,
    });
    return request.data;
  }

  static async reopen(
    check_in_id: string|number,
    data: CheckInReopenRequest,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data,
      method: 'patch',
      url: `/api/admin/check-ins/${check_in_id}/reopen`,
    });
    return request.data;
  }

  static async emailOutbound(
    check_in_id: string|number,
    include_driver: boolean,
    files: number[],
    emails: string[],
    email_message: string,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      data: {
        include_driver,
        files,
        emails,
        email_message,
      },
      method: 'post',
      url: `/api/admin/check-ins/${check_in_id}/email-outbound`,
    });
    return request.data;
  }
}
