import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import BSNav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeUp, faVolumeMute, faBell, faCog, faUserNinja,
} from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useCallback, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Role, { roleMethod } from '../../Auth/Role';
import { useAudio } from '../../Context/AudioContext';
import { useUser } from '../../Context/UserContext';
import { useAuth } from '../../Context/AuthContext';
import MessageNotificationsList from './MessageNotificationsList';
import routeique from '../../assets/images/routeique-logo.png';
import PendingCheckInsList from './PendingCheckInsList';
import { CheckIn, CheckInRequestFilter } from '../../types/check-in';
import AdminCheckIns from '../../Data/AdminCheckIns';
import { PaginatedResultSet } from '../../types/laravel';
import useInterval from '../../hooks/useInterval';
import { ADMIN_USER_GUIDE, DRIVER_USER_GUIDE } from '../../config';

const filters: CheckInRequestFilter = {
  state: [
    'pending',
  ],
};

const Nav = () => {
  const { t, i18n } = useTranslation(['nav']);
  const { signOut } = useAuth();
  const { user, notifications } = useUser();
  const { settings, setSettings } = useAudio();
  const [pendingCheckIns, setPendingCheckIns] = useState<CheckIn[]>([]);
  const [pendingCheckInCount, setPendingCheckInCount] = useState<number>(0);

  // We limit the page count here to 5 because we don't want the notification list
  // to become too long. As check in states change, the list becomes refreshed with the
  // oldest check ins appearing at the top.
  const fetchPendingCheckIns = useCallback(() => {
    // Only admins can get pending check ins
    if (user && roleMethod(user, 'Admin')) {
      AdminCheckIns.index(
        1,
        5,
        undefined,
        ['created_at'],
        filters,
      ).then((response: PaginatedResultSet<CheckIn[]>) => {
        setPendingCheckIns(response.data);
        setPendingCheckInCount(response.meta.total);
      });
    }
  }, [user]);

  useInterval(fetchPendingCheckIns, 15000, true);

  const getTotalNotificationCount = () => pendingCheckInCount + notifications.length;

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="dark"
      variant="dark"
      fixed="top"
      sticky="top"
    >
      <Container fluid="xl">
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Brand as={NavLink} to={`/${i18n.language}`}>
          <img
            src={routeique}
            alt="routeique"
            height={30}
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="navbar">
          {user && (
            <BSNav className="ml-auto">
              <Role roleNames="Admin">
                <BSNav.Link
                  as={NavLink}
                  to={`/${i18n.language}/check-ins`}
                >
                  {t('links.check_ins')}
                </BSNav.Link>
              </Role>
              <Role roleNames="Driver">
                <BSNav.Link
                  as={NavLink}
                  to={`/${i18n.language}/check-ins`}
                >
                  {t('links.check_ins')}
                </BSNav.Link>
              </Role>
              <Role roleNames="Admin">
                <NavDropdown
                  alignRight
                  id="settings"
                  title={<FontAwesomeIcon icon={faCog} />}
                  className="justify-content-end"
                >
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/canned-messages`}
                  >
                    {t('links.canned_messages')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/drivers`}
                  >
                    {t('links.drivers')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/admins`}
                  >
                    {t('links.admins')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/warehouses`}
                  >
                    {t('links.warehouses')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/destinations`}
                  >
                    {t('links.destinations')}
                  </NavDropdown.Item>
                </NavDropdown>
              </Role>
              <Role roleNames="Admin">
                <NavDropdown
                  alignRight
                  id="notifications"
                  title={(
                    <>
                      {getTotalNotificationCount() > 0 && (
                      <Badge
                        pill
                        variant="danger"
                        style={{ position: 'relative', bottom: '10px' }}
                      >
                        {getTotalNotificationCount()}
                      </Badge>
                      )}
                      <FontAwesomeIcon icon={faBell} />
                    </>
                  )}
                  className="justify-content-end"
                >
                  <PendingCheckInsList
                    pendingCheckIns={pendingCheckIns}
                    pendingCheckInCount={pendingCheckInCount}
                  />
                  <NavDropdown.Divider />
                  <MessageNotificationsList
                    notifications={notifications}
                  />
                </NavDropdown>
              </Role>
              <NavDropdown
                alignRight
                id="user"
                title={<FontAwesomeIcon icon={faUserNinja} />}
                className="justify-content-end"
              >
                <NavDropdown.Item
                  onClick={() => setSettings({ ...settings, mute: !settings.mute })}
                >
                  <FontAwesomeIcon icon={(settings.mute ? faVolumeUp : faVolumeMute)} />
                  {(settings.mute ? t('links.enable_sound') : t('links.mute'))}
                </NavDropdown.Item>
                <Dropdown.Divider />
                {i18n.options?.whitelist && i18n.options.whitelist.filter((subject) => subject !== 'cimode').map((lang) => (
                  <NavDropdown.Item
                    key={lang}
                    active={i18n.language === lang}
                    onClick={() => i18n.changeLanguage(lang)}
                  >
                    {t(`languages.${lang}.short`)}
                  </NavDropdown.Item>
                ))}
                <Dropdown.Divider />
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${i18n.language}/user`}
                >
                  {t('links.profile')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={signOut}
                >
                  {t('links.sign_out')}
                </NavDropdown.Item>
              </NavDropdown>
            </BSNav>
          )}
          {!user && (
            <BSNav className="ml-auto">
              {i18n.options?.whitelist && i18n.options.whitelist.filter((subject) => subject !== 'cimode').map((lang) => (
                <BSNav.Link
                  key={lang}
                  active={i18n.language === lang}
                  onClick={() => i18n.changeLanguage(lang)}
                >
                  {t(`languages.${lang}.short`)}
                </BSNav.Link>
              ))}
            </BSNav>
          )}
          <BSNav>
            <BSNav.Link
              rel="noopener noreferrer"
              target="_blank"
              href={(user && roleMethod(user, 'Admin')) ? ADMIN_USER_GUIDE : DRIVER_USER_GUIDE}
            >
              {t('links.user_guide')}
            </BSNav.Link>
          </BSNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;
