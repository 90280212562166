import Axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import {
  getUser, Jwt, refreshToken, signOut,
} from './AuthClient';
import TokenStorage from './TokenStorage';

let isRefreshing: Promise<Jwt> | null = null;

const forceLogout = () => {
  signOut();
  window.location.reload();
};

/**
 * We initialize our Axios interceptors and other settings.
 */
const initializeAxios = () => {
  Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  Axios.defaults.paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'brackets' });

  Axios.interceptors.request.use((request: AxiosRequestConfig) => {
    const accessToken = TokenStorage.getAccessToken();
    if (accessToken) {
      // Automatically adds our Authorization token to the request if we have
      // on available. Please note, requests that occur in worker threads require
      // the token to be explicitly passed to them.
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  });

  Axios.interceptors.response.use(undefined, async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      const originalRequest = error.config;

      // eslint-disable-next-line no-underscore-dangle
      if (error.response.status === 403 || originalRequest._retried) {
        // We immediately logout if a 403 is received as someone's more then
        // likely up to no good.
        forceLogout();
      }

      try {
        // If there's already a refresh request we wait until it's done.
        const newConfig = error.config;
        // eslint-disable-next-line no-underscore-dangle
        newConfig._retried = true;
        if (await isRefreshing) {
          newConfig.headers.Authorization = `Bearer ${TokenStorage.getAccessToken()}`;
          return Axios.request(newConfig);
        }
        await refreshToken();
        newConfig.headers.Authorization = `Bearer ${TokenStorage.getAccessToken()}`;
        isRefreshing = Axios.request(newConfig);
        return isRefreshing;
      } catch (e) {
        forceLogout();
        return Promise.reject();
      }
    }

    return Promise.reject(error);
  });
};

async function bootstrapAppData() {
  initializeAxios();
  if (TokenStorage.getAccessToken() || TokenStorage.getRefreshToken()) {
    const user = await getUser();
    return {
      user,
    };
  }
  return { user: null };
}

export default bootstrapAppData;
