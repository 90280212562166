import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { ReactNode } from 'react';
import Nav from '../Nav/Nav';
import PageHeader from './PageHeader';

export interface PageProps {
  metaTitle?: string;
  // Either a component constructor (ie <Foo />) or
  // a fully translatable string within the 'titles' namespace.
  header?: ReactNode | 'string';
}

const Page: React.FunctionComponent<PageProps> = ({ children, metaTitle, header }) => {
  const { t } = useTranslation('titles');
  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t('general:title')}`}
        defaultTitle={t('general:title')}
        title={metaTitle}
      >
        {isMobile
          ? <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          : <meta name="viewport" content="width=device-width, initial-scale=1" />}
        <meta name="description" content="Order your products" />
      </Helmet>
      <Nav />
      <Container fluid="xl">
        <main style={{ padding: '1rem 0 2rem' }}>
          {(typeof header === 'string')
            ? <PageHeader titleTag={header} />
            : header}
          {header && <hr />}
          {children}
        </main>
      </Container>
    </>
  );
};

export default Page;
