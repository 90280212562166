import Axios, { AxiosRequestConfig } from 'axios';
import { ResultSet } from '../types/laravel';
import { Notification } from '../types/notifications';

export default class Notifications {
  static async index(
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<Notification[]>> {
    const request = await Axios({
      ...config,
      method: 'get',
      url: '/api/auth/user/notifications',
    });
    return request.data;
  }

  static async show(
    id: number|string,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<ResultSet<Notification>> {
    const request = await Axios({
      ...config,
      method: 'get',
      url: `/api/auth/user/notifications/${id}`,
    });
    return request.data;
  }

  static async read(
    id: number|string,
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      method: 'patch',
      url: `/api/auth/user/notifications/${id}/mark-read`,
    });
    return request.data;
  }

  static async readAll(
    config?: Partial<AxiosRequestConfig>,
  ): Promise<void> {
    const request = await Axios({
      ...config,
      method: 'patch',
      url: '/api/auth/user/notifications/mark-all-read',
    });
    return request.data;
  }
}
