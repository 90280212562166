import React, {
  ReactNode, ReactNodeArray, useEffect, useState,
} from 'react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useAuth } from './AuthContext';
import TokenStorage from '../Data/TokenStorage';

const EchoContext = React.createContext<null|Echo>(null);

interface IBroadcastProviderProps {
    children: ReactNode|ReactNodeArray
}

function EchoProvider(props: IBroadcastProviderProps) {
  const user = useAuth();
  const [echo, setEcho] = useState<Echo|null>(null);

  useEffect(() => {
    const client = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY ?? '', {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          // TODO I'm not sure how well this will work if the token
          //  needs to be refreshed...
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        },
      },
    });

    const newEcho = new Echo({
      broadcaster: 'pusher',
      client,
    });
    setEcho(newEcho);
  }, [TokenStorage.getAccessToken()]);

  if (!user || !TokenStorage.getAccessToken()) {
    return <EchoContext.Provider value={null} {...props} />;
  }

  return <EchoContext.Provider value={echo} {...props} />;
}

function useEcho() {
  const context = React.useContext(EchoContext);
  if (context === undefined) {
    throw new Error('useBroadcast must be used within a BroadcastProvider');
  }
  return context;
}

export { EchoProvider, useEcho };
