import React, { ReactNode, ReactNodeArray } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './AuthContext';
import { EchoProvider } from './EchoContext';
import { AudioProvider } from './AudioContext';

interface AppProvidersProps {
    children: ReactNode|ReactNodeArray
}

function AppProviders({ children }: AppProvidersProps) {
  return (
    <Router>
      <HelmetProvider>
        <AuthProvider>
          <AudioProvider>
            <EchoProvider>
              {children}
              <ToastContainer
                autoClose={10000}
                hideProgressBar
              />
            </EchoProvider>
          </AudioProvider>
        </AuthProvider>
      </HelmetProvider>
    </Router>
  );
}

export default AppProviders;
