import { FitBounds } from 'react-mapbox-gl/lib/map';
import { CheckInMediaTypes } from './types/check-in';

export const DEFAULT_LAT: number = 43.7724085;
export const DEFAULT_LONG: number = -87.9989544;
export const DEFAULT_LAT_LONG: [number, number] = [DEFAULT_LONG, DEFAULT_LAT];
export const DEFAULT_BOUNDS: FitBounds = [
  [-78.680804, 50],
  [-85, 42.978975],
];
export const DEFAULT_ZOOM: [number] = [16];

export const ACCEPTED_MIME_TYPES: CheckInMediaTypes[] = [
  'image/jpeg',
  'image/png',
  'text/plain',
  'text/csv',
  'application/pdf',
  'application/zip',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const DRIVER_USER_GUIDE: string = 'http://support.routeique.com/en/articles/4277809-yard-management-system-driver-user-guide';
export const ADMIN_USER_GUIDE: string = 'http://support.routeique.com/en/articles/4292188-yard-management-system-admin-user-guide';
