import * as React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CheckIn } from '../../types/check-in';

interface PendingCheckInsProps {
  pendingCheckIns: CheckIn[];
  pendingCheckInCount: number;
}

const PendingCheckInsList = ({ pendingCheckIns, pendingCheckInCount }: PendingCheckInsProps) => {
  const { i18n, t } = useTranslation('nav');
  const history = useHistory();

  return (
    <>
      <NavDropdown.Header>
        <strong>
          {t('links.pending_check_ins')}
          {' '}
          {`(${pendingCheckInCount})`}
        </strong>
      </NavDropdown.Header>
      {pendingCheckIns.length > 0 && pendingCheckIns.map((checkIn) => (
        <NavDropdown.Item
          key={checkIn.id}
          onClick={() => {
            history.push(`/${i18n.language}/check-ins/${checkIn.id}`);
          }}
        >
          { `${t(`types:${checkIn.type}`)} - ${checkIn.appointment_number}` }
        </NavDropdown.Item>
      ))}
      {pendingCheckIns.length === 0 && (<Dropdown.Header>{t('links.check_ins_empty')}</Dropdown.Header>)}
    </>
  );
};

export default PendingCheckInsList;
