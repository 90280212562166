import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Backdrop from './Backdrop';

interface FullPageSpinnerProps {
  message?: string;
}

const FullPageSpinner = ({ message }: FullPageSpinnerProps) => {
  const { t } = useTranslation();
  return (
    <Backdrop>
      <div className="m-4 d-flex align-items-center justify-content-center">
        <div className="d-inline">
          <Spinner className="mr-4" animation="border" role="status">
            <span className="sr-only">{message ?? t('glossary.loading')}</span>
          </Spinner>
        </div>
        <div className="d-inline">
          <strong>{message ?? t('glossary.loading')}</strong>
        </div>
      </div>
    </Backdrop>
  );
};

export default FullPageSpinner;
