import React, { PropsWithChildren } from 'react';

interface BackdropProps {}

const Backdrop = ({ children }: PropsWithChildren<BackdropProps>) => (
  <div
    style={{
      position: 'fixed',
      zIndex: 1040,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#000',
      color: 'white',
      opacity: 0.5,
    }}
    className="d-flex justify-content-center align-items-center"
  >
    {children}
  </div>
);

export default Backdrop;
