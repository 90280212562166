import React, {
  ReactNode, ReactNodeArray, useCallback, useState,
} from 'react';
import { User } from '../types/user';
import { Notification } from '../types/notifications';
import Notifications from '../Data/Notifications';
import useInterval from '../hooks/useInterval';

interface UserContextState {
  user: User,
  notifications: Notification[];
  markRead: (id: number|string) => void;
}

const UserContext = React.createContext<UserContextState>({} as UserContextState);

interface IUserProviderProps {
    children: ReactNode|ReactNodeArray;
    user: User;
}

function UserProvider({ user, ...props }: IUserProviderProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const pullNotifications = useCallback(() => {
    Notifications.index().then((response) => setNotifications(response.data));
  }, [user]);
  useInterval(pullNotifications, 15000, true);

  return (
    <UserContext.Provider
      value={{
        user,
        notifications,
        markRead: (id: string|number) => Notifications.read(id).then(pullNotifications),
      }}
      {...props}
    />
  );
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
