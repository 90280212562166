import Axios, { AxiosResponse } from 'axios';
import * as crypto from 'crypto';
import TokenStorage from './TokenStorage';
import { User } from '../types/user';

export interface Jwt {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface PasswordReset {
  email: string;
  token: string;
  password: string;
  password_confirm: string;
}

const getUser = async (): Promise<User> => {
  const request = await Axios({
    method: 'get',
    url: '/api/auth/user',
  });
  return request.data.data;
};

const updateUser = async (data: any): Promise<User> => {
  const request = await Axios({
    method: 'put',
    url: '/api/auth/user',
    data,
  });
  return request.data;
};

const base64URLEncode = (str:Buffer) => str
  .toString('base64')
  .replace(/\+/g, '-')
  .replace(/\//g, '_')
  .replace(/=/g, '');

const sha256 = (buffer: string) => crypto
  .createHash('sha256')
  .update(buffer)
  .digest();

const redirectUser = () => {
  const state = base64URLEncode(crypto.randomBytes(32));
  const code_verifier = base64URLEncode(crypto.randomBytes(32));
  const code_challenge = base64URLEncode(sha256(code_verifier));
  localStorage.setItem('state', state);
  localStorage.setItem('code_verifier', code_verifier);

  const searchParametersData: any = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    response_type: 'code',
    scope: '',
    state,
    code_challenge,
    code_challenge_method: 'S256',
  };

  const searchParameters = new URLSearchParams();

  Object.keys(searchParametersData).forEach(
    (subject) => searchParameters.append(subject, searchParametersData[subject]),
  );

  window.location.replace(`${process.env.REACT_APP_API_BASE_URL}/oauth/authorize?${searchParameters.toString()}`);
};

const acceptCallback = async (): Promise<User> => {
  const params = new URLSearchParams(window.location.search);
  const state = params.get('state');
  const code = params.get('code');
  const code_verifier = localStorage.getItem('code_verifier');

  if (!state || state !== localStorage.getItem('state')) {
    throw Error;
  }

  // Retreive full code.
  const instance = Axios.create();
  const request: AxiosResponse<Jwt> = await instance({
    data: {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
      code_verifier,
      code,
    },
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/oauth/token`,
  });
  TokenStorage.setAccessToken(request.data.access_token);
  TokenStorage.setRefreshToken(request.data.refresh_token);
  return getUser();
};

export interface CodeRequestResponse {
  response: 'sent'|'failed';
}

const codeRequest = async (phoneNumber: string): Promise<CodeRequestResponse> => {
  const instance = Axios.create();
  const request: AxiosResponse<CodeRequestResponse> = await instance({
    data: {
      phone_number: phoneNumber,
    },
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/api/drivers/code`,
  });
  return request.data;
};

export interface DriverLoginRequest {
  phone_number: string;
  password: string;
}

const driverLogin = async (credentials: DriverLoginRequest) => {
  const instance = Axios.create();
  const request: AxiosResponse<Omit<Jwt, 'refresh_token' | 'expires_in'>> = await instance({
    data: {
      phone_number: credentials.phone_number,
      password: credentials.password,
    },
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/api/drivers/login`,
  });
  TokenStorage.setAccessToken(request.data.access_token);
  return request.data;
};

const refreshToken = async (): Promise<null> => {
  // We use an instance of axios free of interceptors
  const instance = Axios.create();
  const request: AxiosResponse<Jwt> = await instance({
    data: {
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '',
      refresh_token: TokenStorage.getRefreshToken(),
    },
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/oauth/token`,
  });
  TokenStorage.setAccessToken(request.data.access_token);
  TokenStorage.setRefreshToken(request.data.refresh_token);
  return Promise.resolve(null);
};

const signOut = () => {
  TokenStorage.clear();
  return Promise.resolve();
};

const forgotPassword = async (data: any): Promise<void> => {
  const request = await Axios({
    method: 'post',
    url: '/auth/forgotPassword',
    data,
  });
  return request.data;
};

const resetPassword = async (data: any): Promise<void> => {
  const request = await Axios({
    method: 'post',
    url: '/auth/oneTimeLogin',
    data,
  });
  return request.data;
};

export {
  refreshToken,
  codeRequest,
  driverLogin,
  getUser,
  updateUser,
  signOut,
  forgotPassword,
  resetPassword,
  redirectUser,
  acceptCallback,
};
