import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from '../Context/UserContext';
import { User } from '../types/user';

interface RoleProps {
  roleNames: string[]|string;
  redirect?: string;
  or?: boolean;
  children: React.ReactNode;
  inputRef?: React.Ref<any>;
}

export const roleMethod = (
  user: User,
  roleNames: string|string[],
  or: boolean = false,
): boolean => {
  let valid = true;
  if (Array.isArray(roleNames)) {
    // If or is false, the user must have all permissions received.
    if (!or) {
      roleNames.forEach((roleName) => {
        if (user.roles.indexOf(roleName) === -1) {
          valid = false;
        }
      });
    } else {
      // Otherwise they only need to have one permission.
      let count = 0;
      roleNames.forEach((roleName) => {
        if (user.roles.indexOf(roleName) !== -1) {
          count += 1;
        }
      });
      // The user didn't have a single permission, so we mark this false.
      if (count === 0) {
        valid = false;
      }
    }
  } else {
    valid = user.roles.indexOf(roleNames) !== -1;
  }

  return valid;
};

// TODO Write a simple test for this since it's super critical for security purposes.
const Role: React.FC<RoleProps> = ({
  roleNames, children, redirect, or = false,
}) => {
  const { user } = useUser();
  const rejectResponse = redirect ? <Redirect to={redirect} /> : null;
  return user && roleMethod(user, roleNames, or)
    ? <>{children}</>
    : rejectResponse;
};

export default React.forwardRef((props:RoleProps, ref) => <Role {...props} inputRef={ref} />);
