import { useTranslation } from 'react-i18next';
import BSRow from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Action extends Pick<ButtonProps, 'variant'>{
  link: string;
  label: string; // Label in the 'actions' namespace
}

interface PageHeaderProps {
  icon?: IconProp
  titleTag: string; // Title in 'titles' namespace
  action?: Action; // Optional action to place in menu.
}

const PageHeader = ({ titleTag, action, icon }: PageHeaderProps) => {
  const { t } = useTranslation(['titles', 'actions']);
  return (
    <BSRow>
      <Col className="d-flex justify-content-between align-items-center">
        <h1>
          {icon && <FontAwesomeIcon icon={icon} />}
          {t(`titles:${titleTag}`)}
        </h1>
        {action && (
          <Button
            as={NavLink}
            to={action.link}
            variant={action.variant}
          >
            {t(`actions:${action.label}`)}
          </Button>
        )}
      </Col>
    </BSRow>
  );
};

export default PageHeader;
