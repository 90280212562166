import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi, { BackendOptions } from 'i18next-http-backend';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

const backend: BackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

const detection: DetectorOptions = {
  lookupFromPathIndex: 0,
  order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
};

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend,
    detection,
    defaultNS: 'general',
    ns: [
      'actions',
      'general',
      'nav',
    ],
    fallbackLng: 'en',
    whitelist: ['en', 'sp'],
    // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'currentOnly',
    // We disable this and handle it ourselves in App.tsx
    react: {
      useSuspense: false,
    },
    // Turn this on if you're having troubles with translations
    debug: false,
  });

export default i18n;
