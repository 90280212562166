import * as React from 'react';
import { UserProvider } from './Context/UserContext';
import FullPageSpinner from './Components/Core/FullPageSpinner';
import { useAuth } from './Context/AuthContext';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const { user } = useAuth();
  // // pre-load the authenticated side in the background while the user's
  // // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp();
  });
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user
        ? <UserProvider user={user}><AuthenticatedApp /></UserProvider>
        : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
