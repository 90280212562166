import * as React from 'react';
import { ReactNode, ReactNodeArray, useState } from 'react';

interface VolumeSettings {
  mute: boolean;
  volume: number;
}

interface AudioContextState {
  settings: VolumeSettings
  setSettings: (newSettings: VolumeSettings) => void;
}

const defaultAudioContextState: VolumeSettings = {
  mute: localStorage.getItem('volume_settings_mute') !== 'false',
  volume: 0.25,
};

const AudioContext = React.createContext<AudioContextState>({
  settings: defaultAudioContextState,
  setSettings: () => {},
});

interface AudioProviderProps {
  children: ReactNode|ReactNodeArray
}

function AudioProvider(props: AudioProviderProps) {
  const [settings, setSettingsState] = useState<VolumeSettings>(defaultAudioContextState);
  const setSettings = (newSettings: VolumeSettings) => {
    localStorage.setItem('volume_settings_mute', newSettings.mute ? 'true' : 'false');
    setSettingsState(newSettings);
  };
  return <AudioContext.Provider value={{ settings, setSettings }} {...props} />;
}

function useAudio() {
  const context = React.useContext(AudioContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { AudioProvider, useAudio };
